import Layout from "../components/Layout";
import Banner from "../components/Banner";
import React from "react";

export default function FormSubmit() {
    return (
        <Layout>
            <Banner heightSmall={true} backgroundImage={'/media/mv-01.jpg'}>
                <div className={"banner-title"}>
                    <span style={{fontSize: "3rem", fontWeight: "bold"}}>Form Submitted</span><br/>
                </div>
            </Banner>
            <article className="section">
                <h1>
                    Thank you for your inquiry. We will be in touch shortly.
                </h1>
                <a href="javascript:history.go(-1)">
                    Previous Page
                </a>

            </article>

        </Layout>
    )
}
